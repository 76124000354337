<template>

    <header :class="{ show: showNav }" :key="nav.length">
        <router-link :to="{ name: 'HomePage' }" class="logo">
            <img src="images/logo.svg" :alt="siteName" width="30" height="30" />
        </router-link>


        <a href="https://www.instagram.com/pascalmaranus.nl" class="social" target="_blank" title="Pascal Maranus op Instagram">
            <i class="fab fa-instagram"></i>
        </a>


        <a href="#" class="contact-cta" @click.prevent="toContact" aria-label="Contact">
            <i class="fa-light fa-sharp fa-comment-dots"></i>
        </a>


        <nav>
            <div>
                <router-link v-for="(item, id) in nav" :key="['item', id].join('-')" :style="`--index: ${id};`" :title="item.name" :to="to(item)">
                    {{ item.name }}
                </router-link>
            </div>
        </nav>

        <a href="#" class="menu-toggle" @click.prevent="toggleNav" aria-label="Menu">
            <span></span>
            <span></span>
            <span></span>
        </a>
    </header>

</template>


<script>
import { mapGetters } from 'vuex';

import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    props: {
        showNav: Boolean,
    },

    
    computed: {
        ...mapGetters([
            'pages',
            'siteName',
            'structure',
        ]),


        nav()
        {
            let nav = [];

            for (let i = 0; i < vm.structure.length; i++)
            {
                let item = vm.structure[i];

                if (item.hidemenu == 0)
                {
                    nav.push(item);
                }
            }

            return nav;
        },
    },


    methods: {
        toContact()
        {
            let cta = document.querySelector('footer');

            // Scroll to the CTA, if there is one
            if (cta)
            {
                cta.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
            }
            // Otherwise, go to the contact page
            else
            {
                vm.$router.push({ name: 'ContactPage' });
            }
        },


        toggleNav()
        {
            vm.$emit('toggle');
        },
    },


	setup()
	{
		const { to } = useFunctions();

		return {
            to,
		};
	},


    created()
    {
        vm = this;
    },    
}
</script>