<template>
    <main class="home">
        <section class="hero">
            <div class="hero-slide" v-for="(c, cid) in categories" :key="cid" :class="{ active: cid == index }">
                <picture class="hero-bg">
                    <source v-for="(img, id) in images" :key="id" :media="img.media" :srcset="imgBase + c.variables[img.key]" :type="img.type" :width="img.size.w" :height="img.size.h" />
    
                    <img :width="screenWidth" :height="(1080 / screenWidth) * 1620" :src="imgBase + c.variables.imageMobileFallback" :alt="c.pagetitle" />
                </picture>
            </div>

            <nav>
                <span v-for="(c, id) in categories" :key="id">
                    <router-link
                        :class="{ active: id == index }"
                        :to="{ name: 'CategoryPage', params: { alias: c.alias } }"
                        @mouseover="setIndex(id, true, $event)"
                        @mouseout="startInterval"
                    >
                        {{ c.pagetitle }}
                    </router-link>
                </span>
            </nav>
        </section>
    </main>
</template>


<script>
import { mapGetters } from 'vuex';

var vm;

export default {
    props: {
        hasTouch: [Boolean, null],
        page: Object,
    },


    data()
    {
        return {
            index: 0,
            interval: null,
        }
    },


    computed: {
        ...mapGetters([
            'categories',
            'imgBase',
            'screenWidth',
            'sizes',
            'webp',
        ]),


        images()
        {
            let ratio = 1080 / vm.screenWidth,
                ratioL = 3840 / vm.screenWidth,
                suffix = vm.webp ? '' : 'Fallback',
                type = 'image/';

            type += vm.webp ? 'webp' : 'jpeg';
    
            return [
                {
                    key: 'imageMobile' + suffix,
                    media: '(orientation: portrait)',
                    size: {
                        w: 1080 * ratio,
                        h: 1620 * ratio,
                    },
                    type: type,
                },
                {
                    key: 'image' + suffix,
                    media: '(orientation: landscape)',
                    size: {
                        w: 3840 * ratioL,
                        h: 2160 * ratioL,
                    },
                    type: type,
                },
            ]
        },
    },


    methods: {
        nextIndex()
        {
            let i = vm.index + 1;

            if (i >= vm.categories.length) i = 0;

            vm.setIndex(i, false);
        },


        setIndex(value, pause, e)
        {
            vm.stopInterval();

            vm.index = value;

            if (!pause || (e && e.type == 'mouseover' && vm.hasTouch)) vm.startInterval();
        },


        startInterval()
        {
            vm.interval = setInterval(vm.nextIndex, 6500);
        },


        stopInterval()
        {
            clearInterval(vm.interval);
        },
    },


    created()
    {
        vm = this;
    },


    mounted()
    {
        vm.startInterval();
    },


    unmounted()
    {
        vm.stopInterval();
    }
}
</script>