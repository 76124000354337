<template>
    <main ref="container" data-scroll-container>
        <section class="banner" data-scroll-section>
            <div class="content" v-html="page.content" />
        </section>

        <section class="album-page" data-scroll-section>
            <div class="album" v-for="(row, id) in rows" :key="id">
                <div class="photo"
                    v-for="(p, rid) in row" :key="rid"
                    :class="[p.variables.orientation, p.hidemenu ? 'private' : null].join(' ')"
                    :data-index="p.id"
                    :data-rating="p.variables.hasOwnProperty('rating') ? p.variables.rating : 0"
                    :data-status="p.hidemenu ? 1 : 0"
                >
                    <a href="#" @click.prevent="selectPhoto(p)">
                        <figure>
                            <picture>
                                <source v-for="(img, id) in getImages(p)" :key="id" :media="img.media" :srcset="img.src" :type="img.type" />
                
                                <img height="960" :width="page.variables.orientation == 'landscape' ? (960 * 1.5) : (960 / 1.5)" :src="imgBase + p.variables.imageFallback" :alt="page.pagetitle" />
                            </picture>
                        </figure>
                    </a>
                </div>
            </div>
        </section>


        <transition name="fade" mode="out-in">
            
            <div class="modal lightbox" v-if="photo" @click.self="closeLightbox">
                <a href="#" class="close" @click.prevent="closeLightbox">
                    <i class="far fa-times-circle"></i>
                </a>

                <transition name="fade" mode="out-in">
                    <picture class="lightbox-image" :key="photoID">
                        <img :src="`${photo.url}`" :alt="photo.title" />
                    </picture>
                </transition>

                <div class="tools" v-if="page.sections.length > 1">
                    <a href="#" @click.prevent="updatePhotoID(-1)">
                        <i class="far fa-angle-left"></i>
                    </a>

                    <span>
                        {{ photoID + 1 }}
                        /
                        {{ page.sections.length }}
                    </span>

                    <a href="#" @click.prevent="updatePhotoID(1)">
                        <i class="far fa-angle-right"></i>
                    </a>
                </div>

                <div class="preloader">
                    <img :key="prevPhoto.url" :src="`${prevPhoto.url}`" rel="preload" />
                    <img :key="nextPhoto.url" :src="`${nextPhoto.url}`" rel="preload" />
                </div>
            </div>

        </transition>


        <slot></slot>

    </main>
</template>


<script>
import imagesLoaded from 'imagesloaded';
import LocomotiveScroll from 'locomotive-scroll';

import { mapGetters } from 'vuex';

var vm;

export default {
    props: {
        page: Object,
    },


    data()
    {
        return {
            height: 480,
            ls: null,
            photoID: -1,
        }
    },


    computed: {
        ...mapGetters([
            'base',
            'baseUrl',
            'imgBase',
            'screenWidth',
        ]),


        nextPhoto()
        {
            return vm.getPhoto(1)
        },


        photo()
        {
            return vm.getPhoto(0);
        },


        photos()
        {
            let photos = JSON.parse(JSON.stringify(vm.page.sections));

            return photos.reverse();
        },


        prevPhoto()
        {
            return vm.getPhoto(-1)
        },


        rows()
        {
            let length = vm.photos.length,
                limit = 1,
                onRowID = 0,
                rowID = 0,
                rows = [[]];


            // Determine the limit per row
            if (vm.screenWidth >= 1920) limit = 3;
            else if (vm.screenWidth >= 768) limit = 2;


            // Create the rows and add photos
            for (let i = 0; i < length; i++)
            {
                let p = vm.photos[i];

                onRowID++;

                rows[rowID].push(p);

                if ((onRowID === limit || (Object.hasOwn(p.variables, 'break') && p.variables.break == 1)) && i < length - 1)
                {
                    rowID++;
                    onRowID = 0;
                    rows.push([]);
                }
            }

            
            /**
             * If the last row only has a single image, take one from the previous to add.
             * But only if there's more than 2 images per row AND more than 1 row.
             **/
            if (rows[rowID].length === 1 && limit > 2 && rowID > 0)
            {
                // Get the previous and last items
                let prevRow = rows[rowID - 1],
                    previous = prevRow[limit - 1],
                    last = rows[rowID][0];

                // Replace the last row with the two of them
                rows[rowID] = [previous, last];

                // Remove the previous item from the second to last row
                prevRow.pop();
                rows[rowID - 1] = prevRow;
            }

            return rows;
        },
    },


    methods: {
        closeLightbox()
        {
            vm.photoID = -1;
        },


        getImages(p)
        {
            return [
                {
                    media: '(max-width: 1919px)',
                    src: vm.imgBase + p.variables.imageMobile,
                    type: 'image/webp',
                },
                {
                    media: '(min-width: 1920px)',
                    src: vm.imgBase + p.variables.image,
                    type: 'image/webp',
                },
                {
                    media: '',
                    src: vm.imgBase + p.variables.imageFallback,
                    type: 'image/jpeg',
                },
            ]
        },


        getPhoto(modifier)
        {
            if (vm.photoID < 0 || vm.photoID >= vm.page.sections.length) return null;

            let id = vm.photoID + modifier;

            if (id < 0) id = vm.page.sections.length - 1;
            else if (id >= vm.page.sections.length) id = 0;

            let p = vm.page.sections[id],
                img = vm.screenWidth < 1366 ? 'imageMobile' : 'image';

            return {
                title: p.pagetitle,
                url: vm.imgBase + p.variables[img],
            }
        },


        selectPhoto(p)
        {
            for (let i = 0; i < vm.page.sections.length; i++)
            {
                if (vm.page.sections[i].id == p.id) vm.photoID = i;
            }
        },

        
        updatePhotoID(modifier)
        {
            let id = vm.photoID + modifier;

            if (id < 0) id = vm.page.sections.length - 1;
            else if (id >= vm.page.sections.length) id = 0;

            vm.photoID = id;
        },
    },


    created()
    {
        vm = this;
    },


    mounted() {
        vm.$nextTick(() => {
            vm.ls = new LocomotiveScroll({
                el: vm.$refs.container,
                smooth: true,
                mobile: {
                    smooth: true
                },
                tablet: {
                    smooth: true
                },
            })
        })

        imagesLoaded(vm.$refs.container, () => {
            vm.ls.update()
        })
    },


	unmounted()
	{
		vm.ls = null;
	},
}
</script>