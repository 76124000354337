<template>
    <main ref="container" data-scroll-container>
        <section data-scroll-section>
            <div class="content" v-html="page.content" />
        </section>


        <slot></slot>
    </main>
</template>


<script>
import LocomotiveScroll from 'locomotive-scroll';

var vm;

export default {
    props: {
        page: Object,
    },


    data()
    {
        return {
            ls: null,
        };
    },


    created()
    {
        vm = this;
    },


    mounted()
    {
        vm.$nextTick(() => {
            vm.ls = new LocomotiveScroll({
                el: vm.$refs.container,
                smooth: true,
                mobile: {
                    smooth: true
                },
                tablet: {
                    smooth: true
                },
            })
        })
    },


    unmounted()
    {
        vm.ls = null;
    },
}
</script>