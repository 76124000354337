<template>
	<transition-group name="load" mode="out-in">
		<article v-if="loaded" key="path" @click="onClick">
			<app-header
                :showNav="showNav"
                @toggle="toggleNav"
            />
			

            <router-view v-if="page" v-slot="{ Component }">
				<transition name="fade" mode="out-in">
					<component
						:is="Component"
						:key="$route.path"
                        :class="hasTouch ? 'touch' : 'no-touch'"
                        :hasTouch="hasTouch"
						:page="page"
						:photo="photo"
						@logout="logoutUser"
					>
                        <cta />
                    </component>

				</transition>
			</router-view>

		</article>


		<loader-element v-else key="loader" />

	</transition-group>
</template>


<script>
import supportsWebP from 'supports-webp';

import { mapActions, mapGetters } from 'vuex';

import usePage from '@/hooks/page.js';

import AppHeader from '@/components/AppHeader.vue';
import CTA from '@/components/CTA.vue';
import LoaderElement from '@/components/LoaderElement.vue';
import LoginForm from '@/components/LoginForm.vue';

var vm;

export default {
	components: {
		AppHeader,
		LoaderElement,
        LoginForm,
        cta: CTA,
	},

	
	data()
	{
		return {
            hasTouch: null,
			showNav: false,
		};
	},


	computed: {
		...mapGetters([
            'imgBase',
			'pages',
			'siteName',
			'structure',
			'user',
		]),


        accessMessage()
        {
            let msg = 'Om deze pagina te mogen bekijken dien je eerst in te loggen.';

            if (vm.$route.name == 'AlbumPage')
            {
                msg = 'Dit album is niet publiekelijk toegankelijk. Is dit jouw album? Log dan in. Als het album met je gedeeld is, neem dan contact op met de eigenaar.';
            }

            return msg;
        },


        hasAccess()
        {
            if (vm.page && !vm.page.hidemenu) return true;
            else if (vm.user !== null) return true;
            
            return false;
        },


		loaded()
		{
			let s = Object.keys(vm.structure),
				p = Object.keys(vm.pages);
			
			return s.length > 0 && p.length > 0;
		},
	},


	methods: {
		...mapActions([
			'_initialize',
			'logout',
            'updateViewport',
            'updateWebp',
        ]),


        detectTouch()
        {
            try {
                document.createEvent('TouchEvent');
                vm.hasTouch = true;
            }
            catch (e)
            {
                vm.hasTouch = false;
            }
        },


        onResize()
        {
            vm.detectTouch();
            vm.updateViewport({ w: parseInt(window.innerWidth), h: parseInt(window.innerHeight)});
        },


		scrollReveal()
		{
            vm.detectTouch();
		},


        toggleNav()
        {
            vm.showNav = !vm.showNav;
        },
	},


	setup()
	{
		const { page } = usePage();


		return {
			page,
		};
	},


	created()
	{
        vm = this;


        // Run the resize functions initially
        vm.onResize();


        // Make sure webp support is checked
        supportsWebP.then(supported => {
            if(!supported)
            {
                document.documentElement.classList.remove('webp');
                document.documentElement.classList.add('no-webp');
                vm.updateWebp(false)
            }
            else
            {
                document.documentElement.classList.remove('no-webp');
                document.documentElement.classList.add('webp');
                vm.updateWebp(true)
            }
        });


        // Initialize the actual application
        vm._initialize();


		window.addEventListener('resize', vm.onResize);
	},


	unmounted()
	{
		window.removeEventListener('resize', vm.onResize);
	},


    watch: {
        $route ()
        {
            vm.showNav = false;
            
            if (vm.page)
            {
                // Update document title
                let separator = ' | ',
                    parts = document.title.split(separator),
                    name = parts.length > 1 ? parts[parts.length - 1] : 'Pascal Maranus';
    
                document.title = [vm.page.title, name].join(separator);
            }
        },
    },
};
</script>


<style lang="scss">
@import 'assets/css/all.min.css';
@import 'assets/css/sharp-light.min.css';
@import 'assets/css/sharp-regular.min.css';
@import 'assets/css/sharp-solid.min.css';
@import 'assets/scss/app.scss';
</style>
