import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default function usePage() {
	const route = useRoute();
    const store = useStore();


    const albums = computed(() => {
        return store.state.albums;
    });
    
    const baseUrl = computed(() => {
        return store.state.baseUrl;
    });

    const pages = computed(() => {
        return store.state.pages;
    });




	const page = computed(() => {
		// Get the pages for the current language
		let modalPaths = [
				// 'prints',
			],
            resources = route.name == 'AlbumPage' ? albums.value : pages.value,
			page = null,
			routePath = route.path;
        
		// For print and product pages, get the overview page
		for (let i = 0; i < modalPaths.length; i++)
		{
			let splitter = `/${modalPaths[i]}/`;

			if (routePath.split(splitter).length > 1)
			{
				routePath = routePath.split(splitter)[0] + splitter;
			}
		}

        if (typeof (resources) != 'undefined')
		{
            for (let i = 0; i < resources.length; i++)
			{
                let p = resources[i],
                    pPath = '/' + p.path.replace(baseUrl.value, '');
				
				if (p.template == 2) pPath = '/';

				if (routePath == pPath) page = p;
			}
	
			if (page)
			{
				// Create some additional paramters
				page.hasImage = Object.keys(page).indexOf('variables') >= 0 && Object.keys(page.variables).indexOf('image') >= 0;
                page.title = page.longtitle && page.longtitle.length > 0 ? page.longtitle : page.pagetitle;
			}
		}
		
		return page;
	});


	return {
		page: page,
	};
}