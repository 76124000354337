import { computed } from 'vue';
// import { useRoute } from 'vue-router';
// import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

export default function useFunctions() {
	// const route = useRoute();
	// const router = useRouter();
	const store = useStore();

	// const base = computed(() => store.state.base);
	const pages = computed(() => store.state.pages);

	
	function getPageById(id)
	{
        let pgs = pages.value;

        for (let i = 0; i < pgs.length; i++)
        {
            let p = pgs[i];

            if (p.id == id)
            {
                return p;
            }
        }
	}


	function isValidEmail(email)
	{
		const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}


    function isValidPassword(pw)
    {
        const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{10,}$/;
        return re.test(String(pw));
    }


	function jsCopy(input)
	{
		return JSON.parse(JSON.stringify(input))
	}


	function to (item)
	{
		let to = {
            name: 'DefaultPage',
            params: {},
        };

        if (Object.prototype.hasOwnProperty.call(item, 'template'))
        {
            switch (parseInt(item.template))
            {
                case 2:
                    to.name = 'HomePage';
                    break;
    
    
                default:
                    to.params.alias = item.alias;
                    break;
            }
        }
        else
        {
            to = {
                path: item.path,
            };
        }

		return to;
	}

	
	return {
		getPageById,
		isValidEmail,
        isValidPassword,
		jsCopy,
		to,
	};
}