import { createRouter, createWebHistory } from "vue-router";

import Category from "@/views/CategoryPage.vue";
import Contact from "@/views/ContactPage.vue";
// import DefaultPage from "@/views/DefaultPage.vue";
import Home from "@/views/HomePage.vue";

const routes = [
    {
        path: "/",
        name: "HomePage",
        component: Home,
    },

    {
        path: '/contact',
        name: 'ContactPage',
        component: Contact,
    },
	
    {
        path: "/:alias",
        name: "CategoryPage",
        component: Category,
    },
];

const router = createRouter({
    scrollBehavior (to, from, savedPosition) {
        return savedPosition ? savedPosition : { top: 0 };
    },
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
