<template>
    <slot />
    
    <form class="login-form primary">

        <transition name="fade" mode="out-in">
            <loader-element v-if="processing" />
        </transition>
        
        <ul>
            <li v-if="feedback !== null" :class="feedbackClass" v-html="feedback" />

            <li>
                <label for="l-email">
                    E-mail
                </label>
                <input id="l-email" type="email" name="email" placeholder="E-mail" v-model="user.email" :class="isRegister ? 'validate' : ''" />
            </li>

            <li>
                <label for="l-pass">
                    Wachtwoord
                </label>
                <input id="l-pass" :type="pwType" name="password" placeholder="Wachtwoord" class="password" v-model="user.password" />

                <i class="fas" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'" @click="showPassword = !showPassword"></i>
            </li>

            <li class="buttons">
                <a href="#" class="button secondary" :class="btnState" @click.prevent="userAction('login')">
                    Log in
                </a>
            </li>
        </ul>

    </form>
</template>


<script>
import axios from 'axios';

import { mapActions, mapGetters } from 'vuex';

import LoaderElement from '@/components/LoaderElement.vue';
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    components: {
        LoaderElement,
    },


    data()
    {
        return {
            feedback: null,
            feedbackClass: '',
            passwordCheck: '',
            processed: false,
            showPassword: false,
            showPasswordCheck: false,
			submitted: false,
			success: null,
            user: {
                email: '',
                password: '',
            },
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'isDev',
        ]),


        btnState()
        {
            let v = true;

            if (!vm.validForm || vm.processing) v = false;

            return v ? '' : 'disabled';
        },


		processing()
		{
			return vm.processed !== vm.submitted;
		},


        pwType()
        {
            return vm.showPassword ? 'text' : 'password';
        },


        pw2Type()
        {
            return vm.showPasswordCheck ? 'text' : 'password';
        },


        validEmail()
        {
            return vm.isValidEmail(vm.user.email);
        },


        validForm()
        {
            if (vm.user.email.length == 0) return false;

            if (!vm.isReset && (vm.user.password.length == 0 || !vm.isValidEmail(vm.user.email))) return false;

            if (vm.isRegister && (vm.user.password != vm.passwordCheck || !vm.isValidPassword(vm.user.password))) return false;

            return true;
        },


        validPassword()
        {
            return vm.isValidPassword(vm.user.password);
        },
    },


    methods: {
        ...mapActions([
            'updateAlbums',
            'updateUser',
        ]),


        async userAction(action)
		{
			vm.submitted = true;
            
            let formData = {
                action: action,
                data: JSON.stringify(vm.user),
            };
            
            
            await axios.post(vm.base + 'forms', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                vm.updateAlbums(response.data.results.albums);
                vm.updateUser(response.data.results.user);
                vm.processed = true;
                vm.success = response.data.results.success;
                vm.toggle();
            })
            .catch(function (error)
            {
                console.log(error);
            });
		},


        toggle()
        {
            setTimeout(() => {
                vm.$emit('toggle')
            }, 13)
        },
    },

    
    setup()
	{
		const { isValidEmail, isValidPassword } = useFunctions();


		return {
            isValidEmail,
            isValidPassword,
		};
	},


    created()
    {
        vm = this;
    },
}
</script>