<template>

    <form class="contact-form primary" id="contact-form">

        <transition name="fade" mode="out-in">

            <loader-element v-if="processing" />

        </transition>

        <div v-if="success === false" class="feedback error" v-html="contactPage.variables.errorMessage" />

        <div class="success" v-if="success" v-html="contactPage.variables.successMessage" />

        <div class="start" v-else>
            <ul>
                <li :class="contact.name.length > 0 ? 'filled' : ''">
                    <label for="c-name">
                        Naam
                    </label>
                    <input type="text" id="c-name" v-model="contact.name" :placeholder="placeholders.name" />
                </li>

                <li :class="validEmail ? 'filled' : ''">
                    <label for="c-email">
                        E-mail
                    </label>
                    <input type="email" id="c-email" v-model="contact.email" :placeholder="placeholders.email" />
                </li>

                <li :class="contact.message.length > 0 ? 'filled' : ''">
                    <label for="c-message">
                        Bericht
                    </label>
                    <textarea id="c-message" v-model="contact.message" :placeholder="placeholders.message" />
                </li>

                <li>
                    <a href="#" :class="buttonClasses" @click.prevent="submitForm">
                        <span>
                            {{ placeholders.button }}
                        </span>

                        <i class="fa-sharp fa-solid fa-paper-plane"></i>
                    </a>
                </li>
            </ul>
        </div>

    </form>
</template>


<script>
import axios from 'axios';

import { mapGetters } from 'vuex';

import LoaderElement from '@/components/LoaderElement.vue';
import useFunctions from '@/hooks/functions.js';

var vm;

export default {
    components: {
        LoaderElement,
    },


    props: {
        page: Object,
        title: {
            type: [String, Boolean],
            default: false,
        },
    },


    data() {
        return {
            contact: {
                name: '',
                email: '',
                message: '',
            },
            placeholders: {
                button: 'Versturen',
                email: 'Jouw mailadres...',
                message: 'Jouw bericht...',
                name: 'Jouw naam...',
            },
            processed: false,
            submitted: false,
            success: null,
        };
    },


    computed: {
        ...mapGetters([
            'base',
            'contactPage',
        ]),


        buttonClasses() {
            let cl = ['button', 'white'];

            if (!vm.valid) {
                cl.push('disabled');
            }

            return cl.join(' ');
        },


        processing() {
            return vm.processed !== vm.submitted;
        },
    

        valid() {
            if (vm.contact.name.length === 0) return false;
            if (vm.contact.email.length === 0) return false;
            if (vm.contact.message.length === 0) return false;

            if (!vm.validEmail) return false;

            return true;
        },


        validEmail() {
            return vm.isValidEmail(vm.contact.email);
        },
    },


    methods: {
        async submitForm()
        {
            vm.submitted = true;

            let formData = {
                action: 'contact',
                data: JSON.stringify(vm.contact),
            };

            
            await axios.post(vm.base + 'forms', formData, {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then(response => {
                vm.processed = true;
                vm.success = response.data.results;
            })
            .catch(function (error) {
                console.log(error);
            });
        },
    },


    setup() {
        const { isValidEmail } = useFunctions();


        return {
            isValidEmail,
        };
    },


    created() {
        vm = this;
    },
}
</script>