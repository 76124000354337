<template>
    <footer v-if="contactPage" data-scroll-section>
        <div class="content" v-html="contactPage.variables.extraContent" />

        <contact-form />
    </footer>
</template>


<script>
import { mapGetters } from 'vuex';

import ContactForm from '@/components/ContactForm.vue'

// var vm;

export default {
    components: {
        ContactForm,
    },


    computed: {
        ...mapGetters([
            'contactPage',
        ]),
    },


    // created()
    // {
    //     vm = this;
    // },
}
</script>