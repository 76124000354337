import { createStore } from "vuex";
// import { useRoute } from "vue-router";

import axios from 'axios';

var base = 'https://api.pascalmaranus.nl/',
    baseUrl = base.replace('api', 'www');

export default createStore({
    state: {
        base: base,
        baseUrl: baseUrl,
        isDev: process.env.NODE_ENV == 'development',
        pages: {},
        siteName: 'Pascal Maranus',
        sizes: [
            {
                'key': 'imageSmall',
                'restriction': '(max-width: 1023px)',
            },
            {
                'key': 'image',
                'restriction': '(min-width: 1024px)',
            },
        ],
        structure: {},
        viewport: {
            w: 0,
            h: 0,
        },
        webp: true,
    },


    getters: {
        base: state => {
            return state.base;
        },


        baseUrl: state => {
            return state.baseUrl;
        },


        categories: state => {
            let c = [];

            for (let i = 0; i < state.pages.length; i++)
            {
                let p = state.pages[i];

                if (p.template == 7) c.push(p);
            }

            return c;
        },


        contactPage: state => {
            let cp = null;

            for (let i = 0; i < state.pages.length; i++)
            {
                let p = state.pages[i];

                if (p.template == 6) cp = p;
            }

            return cp;
        },


        imgBase: state => {
            return state.isDev ? state.baseUrl : '';
        },


        isDev: state => {
            return state.isDev;
        },


        pages: state => {
            return state.pages;
        },


        screenHeight: state => {
            return state.viewport.h;
        },


        screenWidth: state => {
            return state.viewport.w;
        },


        siteName: state => {
            return state.siteName;
        },


        sizes: state => {
            return state.sizes;
        },


        structure: state => {
            return state.structure;
        },


        webp: state => {
            return state.webp;
        },
    },


    mutations: {
        setPages(state, value) {
            state.pages = value;
        },


        setViewport(state, value) {
            state.viewport = value;
        },


        setStructure(state, value) {
            state.structure = value;
        },


        setWebp(state, value) {
            state.webp = value;
        },
    },


    actions: {
        _initialize: async (context, payload) => {
            try {
                const response = await axios.get(base + 'initialize', { params: payload });
                context.commit('setPages', response.data.pages);
                context.commit('setStructure', response.data.structure);
            } catch (error) {
                console.log(error);
            }
        },


        updateViewport: ({ commit }, payload) => {
            commit('setViewport', payload)
        },


        updateWebp: ({ commit }, payload) => {
            commit('setWebp', payload)
        },
    },
});
